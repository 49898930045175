import React, { useEffect, useState } from 'react';
import { Startup } from '../types/Startup'; // Import the Startup type from the appropriate module
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth, db, functions } from './firebaseConfig';
import { User, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { Button, CircularProgress, Stack, TextField, ThemeProvider, createTheme } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import AddBoxIcon from '@mui/icons-material/AddBox';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ConstructionIcon from '@mui/icons-material/Construction';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import DownloadIcon from '@mui/icons-material/Download';


import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks/useTreeViewApiRef';
import { httpsCallable } from 'firebase/functions';
import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from '@mui/x-data-grid';

import Editor from '@monaco-editor/react';

import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import SettingsIcon from '@mui/icons-material/Settings';


// Logged out

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {
    console.log('login');

    await signInWithEmailAndPassword(auth, email, password);
  }
  const handleSignup = async () => window.location.href = '/signup';

  return (
    <Stack flex={1} alignContent="center" padding={2} justifyContent="center" justifyItems="center" height="100vh" sx={{
      background: 'linear-gradient(45deg,#14beff 30%, #fff 70%)'
    }}>

      <form>
        <Stack spacing={2} maxWidth={500} margin="auto">
          <img src={require('./file.png')} alt="logo" style={{ width: '100%', position: 'relative', top: 100, marginTop: -300 }} />

          <Typography variant='h4' textAlign="center" mb={2} fontFamily='Arial, sans-serif'>Login</Typography>
          <TextField
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Button onClick={handleSubmit} variant="contained">Login</Button>
          <Button onClick={handleSignup}>Signup for an account</Button>
        </Stack>
      </form>
    </Stack >
  );
}

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {
    await createUserWithEmailAndPassword(auth, email, password).then(() => {
      window.location.href = '/';
    });
  }

  const handleLogin = async () => window.location.href = '/';

  return (
    <Stack flex={1} alignContent="center" padding={2} justifyContent="center" justifyItems="center" height="100vh" sx={{
      background: 'linear-gradient(45deg,#14beff 30%, #fff 60%)'
    }}>
      <form>
        <Stack spacing={2} maxWidth={500} margin="auto">
          <img src={require('./file.png')} alt="logo" style={{ width: '100%', position: 'relative', top: 100, marginTop: -300 }} />
          <Typography variant='h4' textAlign="center" mb={2} fontFamily='Arial, sans-serif'>Signup</Typography>
          <TextField
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Button onClick={handleSubmit} variant='contained'>Signup</Button>
          <Button onClick={handleLogin}>Already have an account</Button>
        </Stack>
      </form>
    </Stack>
  );
}

// Logged in


const Header = () => {
  const [credits, setCredits] = useState(0);
  const pages = [
    {
      page: <><AddBoxIcon fontSize='large' /><Typography marginLeft={1} variant='body2'>New Idea</Typography></>,
      pageName: 'New Idea',
      pageLink: '/idea-creator',
    },
    // {
    //   page: 'Startup Creator',
    //   pageLink: '/startup-creator',
    // },

  ];
  const settings = [
    {
      page: 'Account',
      pageLink: '/account',
    },
    {
      page: 'Logout',
      pageLink: '/logout',
    },
  ];

  const fetchCredits = () => {
    const currentUserUid = auth.currentUser?.uid;
    if (!currentUserUid) {
      // Handle the case where the user is not logged in
      return;
    }

    const docRef = doc(db, 'usage', currentUserUid);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setCredits(docSnap?.data()?.credits as number || 0);
      } else {
        console.log("No such document!");
      }
    });

    // Remember to unsubscribe from the snapshot when you're done
    return unsubscribe;
  }

  useEffect(() => {
    fetchCredits();
  }, [])

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'transparent', color: 'black' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div style={{
            backgroundImage: `url(${require('./file.png')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '140px',
            height: '80px',
            backgroundPosition: 'center',
            position: 'relative',
            top: '3px',
            cursor: 'pointer'
          }} onClick={() => window.location.href = '/'} />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(({ page, pageLink }) => (
                <MenuItem key={pageLink} onClick={() => {
                  window.location.href = pageLink;
                }}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Arial, sans-serif',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Your Brand
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ page, pageLink }) => (
              <Button
                key={pageLink}
                onClick={() => {
                  window.location.href = pageLink;
                }}
                sx={{ my: 2, display: 'block' }}
                style={{ alignContent: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', border: 'thin solid #fff', marginRight: 8 }}
                variant='outlined'
              >
                {page}
              </Button>
            ))}
          </Box>
          <MenuItem onClick={() => window.location.href = '/products'} sx={{ border: 'thin solid #fff', background: '#1976d2', color: 'white', borderRadius: 2 }}>
            <Typography textAlign="center" fontWeight='bold' variant='body1'>{credits} Build Credits</Typography>
          </MenuItem>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} style={{ justifyContent: 'center', border: 'thin solid #fff', borderRadius: 3, padding: 8, paddingRight: 12, paddingLeft: 12, background: 'white', color: '#1976d2' }}>
                <SettingsIcon fontSize='large' /> <Typography variant='body1' marginLeft={1}>Settings</Typography>
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({ page, pageLink }) => (
                <MenuItem key={page} onClick={() => {
                  window.location.href = pageLink;
                }}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>


        </Toolbar>
      </Container>
    </AppBar>
  );
};

const LayoutElement = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
}





const StartupCreator = () => {
  const [step, setStep] = useState(0);
  const steps = [
    { id: 1, description: 'Enter a description of the platform', fields: { type: 'textarea', name: 'platformDescription' } },
    { id: 2, description: 'Review and edit the company documentation, market requirements, product brief, and user stories' },
    { id: 3, description: 'Review and edit the project architecture plan' },
    { id: 4, description: 'Review and edit the project tasks' },
    { id: 5, description: 'Review and edit the project code' },
    { id: 6, description: 'Build the project and fix any issues' },
    { id: 7, description: 'View and access your project via browser' },
    { id: 8, description: 'Make additional changes to the project and rebuild as needed' },
    { id: 9, description: 'One click deploy the project to firebase hosting' },
    { id: 10, description: 'View the project live on the internet' },
  ];

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'textarea':
        return <Field as={TextField} name={field.name} multiline rows={9} />;
      // handle other field types...
      default:
        return null;
    }
  };

  return (
    <LayoutElement>
      <div>
        <h1>Startup Creator</h1>
        <h2>{steps[step].description}</h2>
        <Formik
          initialValues={{ platformDescription: '' }}
          onSubmit={(values, { setSubmitting }) => {
            // handle form submission
            setSubmitting(false);
          }}
        >
          <Form>
            {steps[step].fields && renderField(steps[step].fields)}
          </Form>
        </Formik>
        <Button onClick={prevStep} disabled={step === 0} variant='outlined'>
          Previous
        </Button>
        <Button onClick={nextStep} disabled={step === steps.length - 1} variant='outlined' >
          Next
        </Button>
      </div>
    </LayoutElement>
  );
};

const IdeaCreator = () => {
  const handleIdeaCreation = httpsCallable(functions, 'handleIdeaCreation');


  return (
    <LayoutElement>
      <Stack m={2} maxWidth={1000} marginX="auto">
        <Typography variant='h4' textAlign="center" mb={2} fontFamily='Arial, sans-serif'>New Idea</Typography>
        <Typography variant='h5' textAlign="center" mb={2} fontFamily='Arial, sans-serif'>Enter your idea below, and we'll help you build it.</Typography>
        <Formik
          initialValues={{ idea: '' }}
          onSubmit={(values, { setSubmitting }) => {
            handleIdeaCreation(values)
              .then((result) => {
                console.log('submitting', { values, result: JSON.stringify(result) });

                setSubmitting(false);

                // @ts-ignore
                window.location.href = `/startup/${result?.data?.id}`;
              })
              .catch((error) => {
                console.error(`Error calling function: ${error.message}`);
                setSubmitting(false);
                // TODO: Display error message to the user
              });
          }}>

          {({ isSubmitting }) => (
            <Form>
              <Stack spacing={2}>
                <Field as={TextField} name="idea" multiline rows={9} label="For example: A website focused on helping people find the best coffee shops in their area" />
                <Button type="submit" variant='contained' disabled={isSubmitting} size='large'>
                  {isSubmitting ? <CircularProgress size={24} /> : <><ConstructionIcon fontSize='large' /><Typography marginLeft={1} variant='body2' fontSize='large'>Start Building</Typography></>}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </LayoutElement>
  );
}

const StartupDashboard = () => {

  const [startups, setStartups] = useState<Startup[]>([]); // Add the type annotation to the startups state

  const [loading, setLoading] = useState(true);

  // Fetch all startups via snapshot
  useEffect(() => {
    const fetchStartups = async () => {
      const q = query(collection(db, 'startup'), where('userId', '==', auth.currentUser?.uid));
      const querySnapshot = await getDocs(q);

      const startups = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      // @ts-ignore
      setStartups(startups);
      setLoading(false);
    }

    fetchStartups();
  }, []);
  const handleStartupClick = (id: string) => {
    window.location.href = `/startup/${id}`;
  }
  const columns = [
    { field: 'idea', headerName: 'Idea', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) => params.toDate().toLocaleString()
    },
    {
      field: 'id',
      headerName: 'Actions',
      // flex: 1,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleStartupClick(params.id)}>View</Button>
      )
    },
  ];

  return (
    <LayoutElement>
      <Box sx={{ width: '100%', padding: 2 }} maxWidth={1000} marginX="auto">
        <Typography variant="h4" textAlign="center" mb={2} fontFamily='Arial, sans-serif'>Startup Dashboard</Typography>
        {
          loading ? <CircularProgress /> : (<>
            {startups.length > 0 ? <DataGrid rows={startups} columns={columns} /> : <Stack justifyContent="center" alignItems="center" height="100%"><Typography variant="h6" marginBottom={2}>No startups found</Typography><Button onClick={() => window.location.href = '/idea-creator'} variant="contained">Create a new idea</Button></Stack>}
          </>
          )
        }
      </Box>
    </LayoutElement>
  );
}

const StartupPage = () => {
  const [startup, setStartup] = useState<Startup | null>(null);

  // Loading state for the startup
  const [loading, setLoading] = useState(true);

  // Loading state for code generation
  const [codeGenerationLoading, setCodeGenerationLoading] = useState(false);

  const [currentDoc, setCurrentDoc] = useState<null | string>(null);
  const [subDoc, setSubDoc] = useState<null | string>(null);

  const [improvements, setImprovements] = useState<string>('');

  const [transientImprovement, setTransientImprovement] = useState('');
  const startupId = window.location.pathname.split('/').pop();

  console.log("Startup: ", startup)
  const apiRef = useTreeViewApiRef();

  useEffect(() => {
    if (!startupId) {
      // Handle the case where the user is not logged in
      return;
    }

    const docRef = doc(db, 'startup', startupId);

    const unsubscribe = onSnapshot(docRef, (doc) => {
      setStartup(doc.data() as Startup || null);
      setLoading(false);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [startupId]);

  const handleGenerateCode = () => {
    setCodeGenerationLoading(true);
    const handleCodeGeneration = httpsCallable(functions, 'handleCodeGeneration');

    handleCodeGeneration({ startupId })
      .catch((error) => {
        // Getting the error details
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log(code, message, details);
      }).finally(() => {
        setCodeGenerationLoading(false);
      });
  }

  const handleImprovement = () => {
    setLoading(true);
    const handleZeroShotPrompt = httpsCallable(functions, 'handleZeroShotPrompt');

    if (!currentDoc) {
      return;
    }

    handleZeroShotPrompt({
      prompt: `You are a world class founder, you have created a document for your startup. Your board members have reviewed the document and have provided feedback. Please incorporate the feedback and make the necessary improvements to the document.

    Requested change:
    ${improvements}
    
    Document:
    ${startup?.[currentDoc]}

    Please output the document inside the code block below:
    \`\`\`plaintext
      Improved document
    \'\'\'
    ` })
      .then((result) => {
        // Read result of the Cloud Function.
        const updatedDocument = extractDocument(result.data as string);

        console.log("Extracted document: ", updatedDocument);

        setTransientImprovement(updatedDocument);
        setImprovements('')
      })
      .catch((error) => {
        // Getting the error details
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log(code, message, details);
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleDocumentUpdate = async (id: string, fieldToChange: string) => {
    console.log('updating document', transientImprovement);

    const startupChanges = { [fieldToChange]: transientImprovement };
    await setDoc(doc(db, 'startup', id), startupChanges, { merge: true })
      .then(() => {
        alert('Document updated');
        setTransientImprovement('')
      })
      .catch((error) => {
        alert(`Error updating document: ${error}`);
      });
  }

  useEffect(() => {
    setTransientImprovement('');
  }, [currentDoc]);

  const stages = [
    {
      stage: '1. Idea',
      stageLink: 'idea',
      waitingText: 'Please wait, this step is still generating...'
    },
    {
      stage: '2. Product Brief',
      stageLink: 'productBrief',
      waitingText: 'Please wait, this step is still generating...'
    },
    {
      stage: '3. User Stories',
      stageLink: 'userStories',
      waitingText: 'Please wait, this step is still generating...'
    },
    {
      stage: '4. Pages',
      stageLink: 'pages',
      format: 'json',
      waitingText: 'Please wait, this step is still generating...'
    },
    {
      stage: '5. Pages code',
      stageLink: 'pagesCode',
      format: 'json',
      waitingText: 'Please click "Generate code" in order to unlock this step.'
    },
    {
      stage: '6. Page elements',
      stageLink: 'pageElements',
      format: 'json',
      waitingText: 'Please click "Generate code" in order to unlock this step.'
    },
  ]

  // [
  //   'brandCreation',
  //   'marketRequirementsDocument',
  //   'businessPlan',
  //   'executiveSummary',
  //   'companyDescription',
  //   'marketAnalysis',
  //   'productLine',
  //   'marketingSalesStrategy',
  //   'marketResearchReport',
  //   'industryOverview',
  //   'targetMarketAnalysis',
  //   'competitiveAnalysis',
  //   'marketTrends',
  //   'swotAnalysis',
  //   'productRoadmap',
  //   'salesPitchDeck',
  //   'investorOnePager',
  // ]

  const currentDocument = currentDoc ? startup?.[currentDoc] : null;
  const currentStage = stages.find((stage) => stage.stageLink === currentDoc);


  const renderContent = () => {
    if (currentStage?.format === 'json') {
      const currentSubDoc = currentDocument.find((doc) => doc.name === subDoc);
      console.log("Current sub doc: ", { currentSubDoc, currentDoc, subDoc })
      return <Stack direction="row" style={{ flex: 1, width: '100%' }}>

        <SimpleTreeView>

          {
            currentDocument.map((doc) => {
              return <TreeItem itemId={doc.name} label={doc.name} onClick={() => setSubDoc(doc.name)} />
            })
          }
        </SimpleTreeView>
        {
          currentSubDoc && <Editor height="90vh" defaultLanguage="react" value={currentSubDoc.code ?? JSON.stringify(currentSubDoc, null, 2)} />

        }
      </Stack>
    }

    return <Box paddingX={2}>
      <Markdown
        children={currentDocument || ''}
        remarkPlugins={[remarkGfm]}
      />
    </Box>
  }

  const generateZipFile = async () => {
    const zip = new JSZip();

    startup?.pagesCode.forEach((page) => {
      zip.file(`${page.pathName}/${page.fileName}`, page.code);
    });

    startup?.pageElements.forEach((element) => {
      zip.file(`${element.pathName}/${element.fileName}`, element.code);
    });

    // Generate the zip file as a blob
    const content = await zip.generateAsync({ type: 'blob' });

    // Trigger the download
    saveAs(content, 'generatedFiles.zip');
  };

  return (
    <LayoutElement>
      <Box>
        {
          (loading || codeGenerationLoading) ? <CircularProgress /> : (
            <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
              <Stack>
                <Box sx={{ minHeight: 352, minWidth: 250, borderRight: "thin solid #eee", height: '100vh' }}>
                  <Typography bgcolor="lightblue" paddingY={1} paddingX={2} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Build Stages
                  </Typography>
                  <SimpleTreeView apiRef={apiRef}>
                    {
                      stages.map((stage) => (
                        <TreeItem sx={{ borderBottom: "thin solid #eee" }} onClick={() => setCurrentDoc(stage.stageLink)} itemId={stage.stageLink} label={<>{!startup?.[stage.stageLink] && <LockIcon />}{stage.stage}</>} />
                      ))
                    }
                  </SimpleTreeView>
                </Box>
              </Stack>
              {currentDoc && !!currentDocument ? (
                <Stack flex={1} padding={2}>
                  {currentDoc && <Typography marginBottom={2} variant="h4">{currentDoc.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</Typography>}
                  {
                    currentDoc && currentDoc !== 'pages' && <Stack bgcolor="lightblue" paddingY={3} paddingX={4} borderRadius={3} marginBottom={2}>
                      <Typography variant="h6">Not what you were expecting?</Typography>
                      <TextField
                        id="outlined-multiline-static"
                        label="AI Improvement"
                        multiline
                        rows={4}
                        defaultValue=""
                        placeholder='Enter your improvement here' onChange={(e) => setImprovements(e.target.value)} />
                      <Button variant="contained" onClick={handleImprovement} style={{ marginTop: 12 }}>Submit</Button>
                    </Stack>
                  }
                  <Stack direction="row" spacing={2} sx={{ border: 'thin solid #eee' }} borderRadius={2} overflow="hidden">
                    {currentDoc && <Box sx={{ flex: 1 }}><Typography bgcolor="lightblue" marginBottom={2} padding={1} variant="h6">Current version </Typography>{renderContent()}</Box>}
                    {startupId && currentDoc && transientImprovement.length > 0 && <Box sx={{ flex: 1 }}><Typography variant="body1">Improvements: </Typography><TextField sx={{ width: '100%' }} id="outlined-multiline-static" label="AI Improvement" multiline rows={20} value={
                      transientImprovement} onChange={(e) => setTransientImprovement(e.target.value)} />
                      <Button variant='outlined' onClick={() => handleDocumentUpdate(startupId, currentDoc)}>Update Document</Button>
                    </Box>}
                  </Stack>
                </Stack>
              ) : <Typography margin="auto" textAlign="center" variant='h4' padding={2}>{currentDoc ? currentStage?.waitingText : "Please choose a step"}</Typography>}
            </Stack>
          )
        }
      </Box>
    </LayoutElement>
  );
}

const Products = () => {
  const products = [
    {
      name: '100 Build credits',
      description: 'One-time purchase',
      cost: '$20'
    },
    {
      name: 'Starter',
      description: '200 Build credits',
      cost: '$40/Month'
    },
    {
      name: 'Pro',
      description: '500 Build credits',
      cost: '$95/Month',
      originalPrice: '$100',
      discount: '5%',
      mostPopular: true
    },
    {
      name: 'Agency',
      description: '2000 Build credits',
      cost: '$360/Month',
      originalPrice: '$400',
      discount: '10%'
    }];

  const handleClick = async (product) => {
    const email = auth.currentUser?.email;
    if (email) {
      const purchase = httpsCallable(functions, 'createCheckoutSession');
      const response = await purchase({ product, email });
      console.log("Response: ", response)
      // open result.url in a new tab
      // @ts-ignore
      window.open(response?.data?.toString(), '_blank');
    } else {
      console.log("No user email found");
    }

  }

  return (
    <LayoutElement>
      <Stack sx={{ width: '100%', maxWidth: 1000, margin: '0 auto', padding: '2em 0' }}>
        <Typography variant='h2' textAlign="center" gutterBottom>Products</Typography>
        <Typography variant='h4' textAlign="center" gutterBottom>Choose a plan that works for you</Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          {products.map((product) => (
            <Stack
              key={product.name}
              sx={{
                width: { xs: '100%', sm: '30%' },
                padding: 2,
                border: '1px solid #ddd',
                borderRadius: 2,
                backgroundColor: product.mostPopular ? 'lightblue' : '#fff',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={require('./file.png')} alt="logo" style={{ width: '100%' }} />
              <Typography variant="h6" gutterBottom>{product.name}</Typography>
              <Typography variant="body2" gutterBottom>{product.description}</Typography>
              <Typography variant="body1" gutterBottom><strong>{product.cost}</strong> {product.discount && `(${product.discount} off)`} <s>{product.originalPrice}</s></Typography>
              <Button variant='contained' onClick={() => handleClick(product.name)}>Buy</Button>
            </Stack>
          ))}
        </Stack>
        <Typography variant="body1" textAlign="center" marginTop={4}>For questions or custom plans, please contact us at sales@tryagent.ai</Typography>
      </Stack> </LayoutElement>
  );
};

const AccountPage = () => {

  const [userAccount, setUserAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  // callable createCustomerPortalSession
  const createCustomerPortalSession = httpsCallable(functions, 'createCustomerPortalSession');

  const fetchUserAccount = async () => {
    const currentUserUid = auth.currentUser?.uid;
    if (!currentUserUid) {
      // Handle the case where the user is not logged in
      return;
    }

    const docRef = doc(db, 'users', currentUserUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // @ts-ignore
      setUserAccount(docSnap.data());
    } else {
      console.log("No such document!");
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchUserAccount();
  }, []);

  const handlePortalClick = async () => {
    // @ts-ignore
    const response = await createCustomerPortalSession({ stripeCustomerId: userAccount?.stripeCustomerId });
    console.log("Response: ", response);
    // open result.url in a new tab
    // @ts-ignore
    window.open(response?.data?.url, '_blank');
  }

  const renderAccountDetails = () => {
    if (loading) {
      return <CircularProgress />
    }
    // @ts-ignore
    return userAccount?.stripeCustomerId && <Button onClick={handlePortalClick} variant='contained'>Manage your account</Button>;
  }

  return (
    <LayoutElement>
      <Box maxWidth={1000} margin="auto" justifyContent="center" textAlign="center" alignContent="center" padding={2}>
        <Typography variant='h3' textAlign="center" mb={2} fontFamily='Arial, sans-serif'>Account</Typography>
        <Typography variant='h5' textAlign="center" mb={2} fontFamily='Arial, sans-serif'>View and manage your account details</Typography>
        {renderAccountDetails()}
      </Box>
    </LayoutElement>
  );
}

const LogoutPage = () => {
  const logout = async () => {
    await auth.signOut();
    window.location.href = '/';
  }

  useEffect(() => {
    logout();
  }, [])

  return (
    <div>
      <h1>Logging you out...</h1>
    </div>
  );

}

function App() {
  const [user, setUser] = useState<User | null>(null);
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            overflow: 'hidden',
            borderRadius: '5px',
          },
        }
      },
    }
  });
  auth.onAuthStateChanged((user) => {
    setUser(user);
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Logged out */}
          {
            !user && (
              <>
                <Route path="/" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
              </>
            )
          }

          {
            user && (
              <>
                <Route path="/" element={<StartupDashboard />} />
                <Route path="/idea-creator" element={<IdeaCreator />} />
                <Route path="/startup-creator" element={<StartupCreator />} />
                <Route path="/startup/:id" element={<StartupPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/products" element={<Products />} />
                <Route path="/logout" element={<LogoutPage />} />
              </>
            )
          }

        </Routes>
      </Router>
    </ThemeProvider>

  );
}

export default App;

const extractDocument = (jsonText) => {
  console.log("Extracting document from: ", jsonText)
  const jsonPattern = /```plaintext\s*([\s\S]*?)\s*```/gm;

  return [...jsonText.matchAll(jsonPattern)].map((match) => {
    console.log("Match: ", match[1])
    return match[1];
  }).flat().join("\n");  // Filter out null values
};