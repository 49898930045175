import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { getAuth, browserLocalPersistence } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD-ceA9BtSTR1gQEKJOGHH4gXmt9MVVTUo",
    authDomain: "startup-in-a-box-5d91c.firebaseapp.com",
    projectId: "startup-in-a-box-5d91c",
    storageBucket: "startup-in-a-box-5d91c.appspot.com",
    messagingSenderId: "509854355429",
    appId: "1:509854355429:web:07032b66868be05f5f690c",
    measurementId: "G-JVHEX7CN57"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = initializeFirestore(app, {
    experimentalForceLongPolling: true,
});
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

// Set auth persistence
auth.setPersistence(browserLocalPersistence)
    .then(() => {
        console.log('Auth persistence set to local');
    })
    .catch((error) => {
        console.error('Failed to set auth persistence', error);
    });

export default app;

export {
    db,
    auth,
    storage,
    functions
};